import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Notification from "./Notification";
import { getNfgMerchantToken,postRequestauth } from "../backendServices/ApiCalls";
import { UserContext } from "../contexts/UserContext";
import Varification2FaLogin from "./Varification2FaLogin";

function LoginCard() {
  const { user, setUser, verifyToken } = useContext(UserContext);

  const navigate = useNavigate();
  const [localtoken, setlocaltoken] = useState({token:"",user:null});
  const [loading, setLoading] = useState(false);
  const [faModalOpen, setFaModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const closeverificationModal = () => {
    setFaModalOpen(false);
  };
  const handleSignin = () => {
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }
  
    setLoading(true);
  
    const normalizedFormData = {
      ...formData,
      email: formData.email.toLowerCase(),
    };
  
    postRequestauth(
      "auth/login",
      normalizedFormData,
      (response) => {
        const data = response?.data;
  
        if (response.status === 200) {
          if (data?.user?.FAverified) {
            setFaModalOpen(true);  
            setlocaltoken({
              token: data?.token,
              user: data?.user,
            });
          } else {
            localStorage.setItem("token", data?.token);  
            setUser(data?.user);  
  
            // Check if the user type is admin
            if (data?.user?.type === "admin") {
              navigate("/adminpanal"); // Navigate to admin dashboard
            } else {
              navigate("/"); // Navigate to regular user dashboard
            }
  
            getNfgMerchantToken(
              (response) => console.log("NFG Merchant Token:", response),
              (error) => console.error("Error in getting NFG Merchant Token:", error)
            );
  
            Notification.showNotification(
              "success",
              "Success",
              "User successfully logged in."
            );
          }
        } else {
          Notification.showNotification("error", "Error", data.message);
        }
        setLoading(false);
      },
      (error) => {
        Notification.showNotification("error", Error, error.response?.data?.message || error.message);
        console.error("An error occurred:", error);
        setLoading(false);
      }
    );
  };
  
  // const handleSignin = () => {
  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return;
  //   }
  
  //   setLoading(true);
  
  //   const normalizedFormData = {
  //     ...formData,
  //     email: formData.email.toLowerCase(),
  //   };
  
  //   postRequest(
  //     "auth/login",
  //     normalizedFormData,
  //     (response) => {
  //       const data = response?.data;
  
  //       if (response.status === 200) {
  //         if (data?.user?.FAverified) {
  //           setFaModalOpen(true);  
  //           setlocaltoken({
  //             token: data?.token,
  //             user: data?.user,
  //           });
  //         } else {
  //           localStorage.setItem("token", data?.token);  
  //           setUser(data?.user);  
  //           navigate("/");
  //             getNfgMerchantToken(
  //             (response) => console.log("NFG Merchant Token:", response),
  //             (error) => console.error("Error in getting NFG Merchant Token:", error)
  //           );
  //             Notification.showNotification(
  //             "success",
  //             "Success",
  //             "User successfully logged in."
  //           );
  //         }
  //       } else {
  //         Notification.showNotification("error", "Error", data.message);
  //       }
  //         setLoading(false);
  //     },
  //     (error) => {
  //       Notification.showNotification("error", "Error", error.response?.data?.message || error.message);
  //       console.error("An error occurred:", error);
  //         setLoading(false);
  //     }
  //   );
  // };
  
  // const handleSignin = () => {
  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return;
  //   }
  
  //   setLoading(true);
  
  //   // Normalize email to lowercase before sending request
  //   const normalizedFormData = {
  //     ...formData,
  //     email: formData.email.toLowerCase(),
  //   };
  
  //   // Post request using postRequest utility
  //   postRequest(
  //     "auth/login",
  //     normalizedFormData,
  //     (response) => {
  //       const data = response?.data;
  
  //       // Check if the response is successful
  //       if (response.status === 200) {
  //         if (data?.user?.FAverified) {
  //           setFaModalOpen(true);  // If 2FA verification is required
  //           setlocaltoken({
  //             token: data?.token,
  //             user: data?.user,
  //           });
  //         } else {
  //           localStorage.setItem("token", data?.token);  // Store token in localStorage
  //           setUser(data?.user);  // Set user data
  //           navigate("/");
  
  //           // Optionally handle the NFG Merchant Token process
  //           getNfgMerchantToken(
  //             (response) => console.log("NFG Merchant Token:", response),
  //             (error) => console.error("Error in getting NFG Merchant Token:", error)
  //           );
  
  //           // Show success notification
  //           Notification.showNotification(
  //             "success",
  //             "Success",
  //             "User successfully logged in."
  //           );
  //         }
  //       } else {
  //         // Handle backend response errors (e.g., invalid credentials)
  //         Notification.showNotification("error", "Error", data.message);
  //       }
  //     },
  //     (error) => {
  //       // Handle error from postRequest
  //       Notification.showNotification("error", "Error", error.response?.data?.message || error.message);
  //       console.error("An error occurred:", error);
  //     }
  //   );
  
  //   setLoading(false);
  // };
  
  // const handleSignin = async () => {
  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return; 
  //   }
  //   setLoading(true);
  //   try {
  //     const normalizedFormData = {
  //       ...formData,
  //       email: formData.email.toLowerCase(),
  //     };
  //     const response = await fetch(`${URL}/auth/login`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(normalizedFormData),
  //     });
  //     const data = await response.json();
  //     // console.log("🚀 ~ handleSignin ~ data:", data);

  //     if (response.status === 200) {
  //       if (data?.user?.FAverified) {
  //         setFaModalOpen(true);
  //         setlocaltoken({
  //             token:data?.token,
  //             user:data?.user})
  //       }else{
  //         localStorage.setItem("token", data?.token);
  //         setUser(data?.user);
  //         navigate("/");
  //         const handleSuccess = (response) => { console.log(''); };
  //         const handleError = (error) => {console.error("");};
  //         getNfgMerchantToken(handleSuccess, handleError);
  //         Notification.showNotification(
  //           "success",
  //           "Success",
  //           "User Successfully Login"
  //         );
  //       }
  //       // console.log(data);
  //       // setTimeout(() => {
  //       //   navigate("/");
  //       // }, 1000);
  //     } else {
  //       Notification.showNotification("error", "Error", data.message        
  //       );
  //       // console.log(data.error);
  //     }
  //   } catch (error) {
  //     Notification.showNotification("error", "Error", "Error");
  //     setLoading(false);
  //     // console.error("An error occurred:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignin();
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mx-6">
      <h1 className="text-accent tracking-tighter mt-16 mb-0 text-center text-6xl font-bold leading-normal">
        Sign In
      </h1>
      <div className="max-w-[488px] mt-6 pt-6 h-[461px] bg-[#222222] mx-auto rounded-3xl">
        <div className="w-16 h-16 rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
          <img src="/icon/yellowProfile.png" className="w-6 h-6" alt="Profile" />
        </div>
        <div className="mx-8 mt-4">
          <p className="text-accent tracking-tighter mb-0 text-lg font-semibold leading-relaxed block">
            Email
          </p>
          <input
            type="email"
            value={formData.email}
            name="email"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            className="border mt-1 px-2 h-12 rounded-xl border-[#383838] w-full bg-transparent text-accent"
          />
        </div>
        <div className="mx-8 mt-4">
          <p className="text-accent tracking-tighter mb-0 text-lg font-semibold leading-relaxed block">
            Password
          </p>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              name="password"
              className="border mt-1 px-2 h-12 rounded-xl border-[#383838] w-full bg-transparent text-accent"
            />
            <span
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-accent"
            >
              {showPassword ? (
                <img src="/icon/openeye.svg" className="size-5"/>
              ):(
                <img src="/icon/closeeye.svg" className="size-5"/>
                 )}

            </span>
          </div>
        </div>
        <button
          onClick={handleSignin}
          disabled={loading}
          className={`bg-primary w-[40%] sm:w-[87%] mt-4 text-black font-medium rounded-xl text-md py-2 mx-auto sm:py-4 sm:mx-8 flex items-center justify-center ${
            loading ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          {" "}
          {loading ? (
            <img
              src="/icon/loader.svg"
              alt="Loading..."
              className="w-7 mx-auto h-7 animate-spin"
            />
          ) : (
            "Sign In"
          )}
        </button>
        <div className="text-end mt-2">
        <Link to="/forgetpasswordemail">
          <span className="bg-secondary cursor-pointer mr-8 text-primary font-sans font-normal text-sm sm:text-base leading-150%">
            Forgot Password
          </span>
        </Link>
        </div>
        <div className="text-center mt-8">
          <Link to="/signup">
            <span className="bg-secondary cursor-pointer text-primary font-sans font-normal text-sm sm:text-base leading-150%">
              Don't have an account? Sign Up
            </span>
          </Link>
        </div>
      </div>
      <Varification2FaLogin
        faModalOpen={faModalOpen} localtoken={localtoken}
        closeverificationModal={closeverificationModal}
      />
    </div>
  );
}

export default LoginCard;
