
import React from 'react';

function Featurecard(props) {
  return (
    <div className='bg-[#222222]  sm:mt-8 2xl:w-[430px] rounded-3xl w-[90%] sm:mx-0 sm:w-96 h-64 transform transition-transform duration-300 hover:-translate-y-2'>
        <img src={props.img} className='w-44 h-24 mx-auto mt-4' alt='experience icon'/>
      
        <p className="font-sora text-[23px] font-semibold leading-25.09 tracking--0.48 text-center">{props.h1}</p>
      <p className='font-montserrat  text-[#AAAAAA] 2xl:px-14 px-4 mt-4 text-[16px] font-normal leading-[24px] text-center'>{props.p}</p>
    </div>
  );
}

export default Featurecard;
