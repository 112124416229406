import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Player from "@vimeo/player";

function Ourvision() {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const playerRef = useRef(null); // Player instance

  useEffect(() => {
    // Player instance create karein
    if (videoRef.current) {
      playerRef.current = new Player(videoRef.current, {
        autopause: true,
        muted: true, // Ensure autoplay works
      });
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      if (isVisible) {
        playerRef.current
          .play()
          .catch((error) => console.error("Video autoplay failed:", error));
      } else {
        playerRef.current.pause();
      }
    }
  }, [isVisible]);

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="400"
      className=" w-[95%]  2xl:w-[80%] 2xl:max-w-[1240px] mt-52 mx-auto"
    >
      <div className="grid grid-cols-1  mx-2 md:grid-cols-2">
        <div className="bg-[#222222] w-auto sm:w-[97%] h-[120%] rounded-3xl ">
          {/* <img
            src="/icon/vision.png "
            className="w-[90%] h-auto mx-auto mt-[10%]"
            alt="Our Vision"
          /> */}
          <div
            ref={videoRef}
            data-vimeo-id="1041692491"
            data-vimeo-autoplay="true"
            className="w-full p-[12%] h-full rounded-3xl mx-auto mt-[2%] md:mt-[5%]"
            // style={{ aspectRatio: "16/9" }}
          ></div>
        </div>
        <div className="relative mt-[16%] md:mt-0 h-[120%]">
          <p className="w-[77.73px]  font-sora text-left  text-primary h-[22.4px] ">
            VALUE
          </p>
          <p className="font-sora text-[44px] mt-2 font-semibold leading-[49.28px]  text-left">
            OUR MISSION
          </p>
          <p className="font-helvetica text-[16px] mt-6 font-medium leading-[24px] text-left ">
            AlgoX Capital makes sophisticated crypto trading accessible to
            everyone. Our proven algorithmic trading strategies deliver
            consistent results, allowing you to participate in the crypto market
            without active trading.
          </p>
          <p className="font-helvetica text-[16px] font-normal mt-2 lg:mt-6 leading-[24px] text-left ">
            Our automated system works 24/7 to identify opportunities and manage
            risks, backed by years of proven performance.
          </p>
          <div className="flex mt-2 lg:mt-12 gap-4 sm:gap-10 ">
            <div>
              <p className="font-sora text-[24px] sm:text-[32px] font-semibold leading-[35.84px] tracking-[-0.64px] text-left">
                + 611%
              </p>
              <p className="font-sora mb-4 text-[11px] sm:text-[14px] text-primary font-normal leading-[19.6px] text-center">
                Since 2018*
              </p>
            </div>
            <div>
              <p className="font-sora text-[24px] sm:text-[32px] ml-2 font-semibold leading-[35.84px] tracking-[-0.64px] text-left">
                4.4x
              </p>
              <p className="font-sora  text-[11px] sm:text-[14px] text-primary font-normal leading-[19.6px] text-left">
                Better than<span className="block"></span> MSCI World
              </p>
            </div>
            <div>
              <p className="font-sora text-[24px] sm:text-[32px] font-semibold leading-[35.84px] tracking-[-0.64px] text-left">
                +100k $
              </p>
              <p className="font-sora text-[11px] sm:text-[14px] text-primary font-normal leading-[19.6px] text-left">
                {" "}
                Generated Profit <span className="block"></span> for our
                costumers
              </p>
            </div>
          </div>
          {/* <Link to="/dashboard">
            <button className="bg-primary mt-6 ml-[35%] sm:ml-[40%] my-auto px-8 h-14 rounded-md hover:bg-[#f1ae1c] hover:text-accent">
              <p className="font-sora text-[16px] text-black font-medium leading-[23.94px] text-left hover:text-accent">
                Try For Free
              </p>
            </button>
          </Link> */}
          <p className="font-sora text-[14px] text-[#9B9B9B80] absolute hidden md:block bottom-0 font-normal leading-[19.6px] text-left">
            *Based on an initial investment of 2,500€ without fees or compound
            interest"
          </p>
          <p className="font-sora text-[14px] text-[#9B9B9B80] md:hidden font-normal leading-[19.6px] text-left">
            *Based on an initial investment of 2,500€ without fees or compound
            interest"
          </p>
        </div>
      </div>
    </div>
  );
}

export default Ourvision;
