import React, { useContext, useEffect, useState } from "react";
import { Switch } from "antd";
import CryptoWithdrawModal from "./CryptoWithdrawModal";
import FiatWithdrawModal from "./FiatWithdrawModal";
import AlgoPoolModal from "./AlgoPoolModal";
import { Select, Space } from "antd";
import DepositVerificationModal from "./DepositVerificationModal";
import { UserContext } from "../contexts/UserContext";
import { getRequest, postRequest } from "../backendServices/ApiCalls";
import Notification from "./Notification";
import { Link } from "react-router-dom";

const Images = {
  close: "./icon/CDM.svg",
  dolphin: "./icon/dolphin.svg",
};

function WithdrawModal({
  show,
  onClose,
  transferBalance,
  setTransferBalance,
  total,
}) {
  const { user } = useContext(UserContext);
  const [modalState, setModalState] = useState({
    bankTransferModal: false,
    cryptoWithdrawModal: false,
    creditCardModal: false,
    verificationModal: false,
  });

  const [selectedOption, setSelectedOption] = useState("Crypto Payment");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(null);
  const [loadingCoin, setLoadingCoin] = useState(false);
  const [amountOptions, setAmountOptions] = useState(false);
  const [coinOptions, setCoinOptions] = useState([]);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [networkOptions, setNetworkOptions] = useState([]);
  const [network, setNetwork] = useState("");
  const [coin, setCoin] = useState("USDT");
  const [finalAmount, setFinalAmount] = useState(0);
  const [finalTransferAmount, setFinalTransferAmount] = useState(0);
  const [showMethods, setShowMethods] = useState(false);
  const [withdrawNextStep, setWithdrawNextStep] = useState(false);
  const [loadingChain, setLoadingChain] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [walletAddress, setWalletAddress] = useState("");
  const [withdrawOTP, setWithdrawOTP] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [amountError, setAmountError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    const fetchExchangeRate = () => {
      getRequest(
        "auth/getsettings",
        (response) => {
          if (response.data.success) {
            setExchangeRate(response.data.exchangeRate);
          } else {
            setError(response.data.message);
          }
        },
        (error) => {
          // setError("Error fetching exchange rate");
          // console.error("An error occurred:", error);
        }
      );
    };

    fetchExchangeRate();
  }, []);
  useEffect(() => {
    const handleWithdraw = async () => {
      setLoadingCoin(true);

      if (!navigator.onLine) {
        Notification.showNotification(
          "error",
          "Network Error",
          "Please check your internet connection and try again."
        );
        setLoadingCoin(false);
        return;
      }

      postRequest(
        "yes",
        "api/bybit/getAllowedDepositCoinInfo",
        {},
        (response) => {
          const allowedCoins = ["USDT"];
          const filteredCoins = response?.data?.result?.configList?.filter(
            (coin, index, self) =>
              allowedCoins.includes(coin.coin) &&
              index === self.findIndex((c) => c.coin === coin.coin)
          );
          setCoinOptions(filteredCoins || []);
          setLoadingCoin(false);
        },
        (error) => {
          Notification.showNotification("error", "Error", "Error");
          console.error("Error making deposit request:", error);
          setLoadingCoin(false);
        }
      );
    };

    handleWithdraw();
  }, []);
  const chain = async (selectedCoin) => {
    // Check for network connectivity
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }

    setLoadingChain(true);

    postRequest(
      "yes",
      "api/bybit/getCoinInfo",
      { coin: selectedCoin },
      (response) => {
        const allowedChains = ["ETH", "BSC", "ARBI", "MATIC", "OP"];
        const filteredChains = response?.data[0]?.chains?.filter((chain) =>
          allowedChains.includes(chain.chain)
        );
        setNetworkOptions(filteredChains || []);
        setLoadingChain(false);
      },
      (error) => {
        Notification.showNotification("error", "Error", "Error");
        console.error("Error fetching coin info:", error);
        setLoadingChain(false);
      }
    );
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const toggleMethods = () => {
    setShowMethods((prev) => !prev);
  };

  const handleNextClick = () => {
    if (amount.trim() !== "") {
    } else {
      setError("Please enter a value before proceeding.");
      return;
    }
    if (amount > transferBalance) {
      let transferAmount;
      if (selectedCurrency === "USD") {
        transferAmount = transferBalance;
      } else if (selectedCurrency === "EUR" && exchangeRate) {
        transferAmount = (transferBalance / exchangeRate).toFixed(2);
      }
      setFinalTransferAmount(transferAmount);
      setError(
        `Your available balance is ${
          selectedCurrency === "USD" ? "$" : "€"
        } ${finalTransferAmount} `
      );
      return;
    }
    if (!selectedOption) {
      alert("Please select a withdrawal method.");
      return;
    }
    if (user.kycStatus === "success") {
      if (selectedOption === "Bank Transfer") {
        setModalState({ ...modalState, bankTransferModal: true });
      } else if (selectedOption === "Debit / Credit Card") {
        setModalState({ ...modalState, creditCardModal: true });
      } else if (selectedOption === "Crypto Payment") {
        let usdAmount;
        if (selectedCurrency === "USD") {
          usdAmount = amount;
        } else if (selectedCurrency === "EUR" && exchangeRate) {
          usdAmount = (amount / exchangeRate).toFixed(2);
        }
        setFinalAmount(usdAmount);
        setModalState({ ...modalState, cryptoWithdrawModal: true });
      }
    } else {
      setModalState({ ...modalState, verificationModal: true });
    }
  };
  const handleCloseModal = (modalType) => {
    setModalState({ ...modalState, [modalType]: false });
  };
  const handleOtpChange = (e, index) => {
    const { value } = e.target;

    if (e.nativeEvent.inputType === "deleteContentBackward") {
      const newOtp = [...otp];
      newOtp[index] = "";
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
      setOtp(newOtp);
    } else if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };
  const handleWithdraw = () => {
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }

    setLoading(true);
    postRequest(
      "yes",
      "api/bybit/submitWithdrawal",
      {
        transferBalance,
        total,
        amount,
        coin,
        network,
        address,
        otp: otp.join(""),
      },
      (response) => {
        if (response.data.status === "success") {
          Notification.showNotification(
            "success",
            "Success",
            response?.data?.message
          );
          setWithdrawNextStep(false);
          setWithdrawOTP(false);
          setTransferBalance(transferBalance - amount);
          setLoading(false);
          onClose();
          // closemainmodal();
          setLoading(false);
        } else {
          Notification.showNotification("error", "Error", response.data.msg);
        }
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.response?.data?.message || "An unexpected error occurred.";
        Notification.showNotification("error", "Error", errorMessage);
        setLoading(false);
      }
    );
  };
  const handlePaste = async () => {
    if (!navigator.clipboard) {
      console.error("Clipboard API not supported");
      return;
    }
    try {
      const text = await navigator.clipboard.readText();
      setAddress(text);
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
      alert(
        "Failed to read clipboard contents. Please ensure your browser allows clipboard access."
      );
    }
  };

  useEffect(() => {
    if (coin) {
      chain(coin);
    }
    if (otp.join("").length === 6) {
      handleWithdraw();
    }
  }, [coin, otp]);

  if (!show) {
    return null;
  }
  const handleChange = (value) => {
    setSelectedCurrency(value);
  };
  const isAnyOtherModalOpen =
    modalState.bankTransferModal ||
    modalState.cryptoModalOpen ||
    modalState.creditCardModal ||
    modalState.verificationModal;
  const handleAmountOptions = () => {
    setAmountOptions(true);
  };
  const handleNextWStepWithdraw = () => {
    if (withdrawNextStep) {
      if (address && amount) {
        setWithdrawOTP(true);
      }
      if (!address) {
        setAddressError("please enter a address before processing");
      }
      if (!amount) {
        setAmountError("please enter a amount before processing");
      }
    } else {
      if (user.kycStatus === "success" && user.FAverified) {
        if (coin === "USDT" && network) {
          setWithdrawNextStep(true);
        } else {
          setError("please enter a value before processing");
        }
        return;
      } else {
        if (coin === "USDT" && network) {
          setModalState({ ...modalState, verificationModal: true });
        } else {
          setError("please enter a value before processing");
        }
      }
    }
  };
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };
  const handleSelect = (value) => {
    setNetwork(value);
    setError("");
    setIsOpen(false); // Close the dropdown after selection
  };
  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
      <div
        className={`bg-Accent p-4 rounded-lg  w-[400px] sm:w-[738px] animate-fadeIn ${
          isAnyOtherModalOpen ? "hidden" : ""
        }`}
      >
        {withdrawOTP ? (
          <div className="fixed inset-0 px-1 flex items-center justify-center z-50  backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
            <div className="w-full max-w-[738px] px-4 sm:px-6 py-8 bg-[#211E34E5] border-[#FFFFFF26] border rounded-3xl relative">
              <div className="flex justify-between">
                <p className="font-sora text-xl font-bold leading-[20.16px] text-left text-white">
                  Enter code
                </p>
                <img
                  src={Images.close}
                  width={20}
                  height={20}
                  onClick={() => setWithdrawOTP(false)}
                  className="cursor-pointer"
                  alt="close-icon"
                />
              </div>
              <p className="font-helvetica text-[12px] font-normal leading-[24px] text-left text-accent2">
                Please check your Authenticator and enter the code.
              </p>
              <div className="rounded-lg bg-[#3F3C4F] px-4 py-7 mt-6">
                <p className="font-title-medium font-sora text-title-medium text-center">
                  Enter Code
                </p>
                <div className="flex justify-center mt-2 space-x-2 sm:space-x-4">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="text"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      maxLength="1"
                    className="h-10 w-10 sm:w-16 sm:h-16 border border-accent2 text-primary bg-transparent rounded-md text-center text-sm sm:text-lg"
                    />
                  ))}
                </div>
              </div>
              {message && (
                <p className="text-center mt-2 text-red-500">{message}</p>
              )}
              <div className="justify-between flex  my-8">
                <button
                  className={`px-[12%] border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
                  onClick={() => setWithdrawOTP(false)}
                >
                  Cancel
                </button>
                <button
                  className={`${
                    loading ? "cursor-not-allowed opacity-50" : ""
                  } px-[1%] sm:px-[4%] border text-[12px] sm:text-[16px] border-primary text-black font-semibold bg-primary py-2 rounded`}
                  onClick={handleWithdraw}
                  disabled={loading}
                >
                  {loading ? (
                    <img
                      src="/icon/loader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    "Confirm Transaction "
                  )}
                </button>
              </div>
              {/* <div className="flex justify-center">
                <button
                  className={`w-full md:w-[50%] mx-auto mt-4 border border-primary text-black font-semibold bg-primary py-2 rounded ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleWithdraw}
                  disabled={loading}
                >
                  {loading ? "Verifying..." : "Verify"}
                </button>
              </div> */}
            </div>
          </div>
        ) : (
          <div className="bg-[#211E34E5] pt-6 border rounded-3xl border-[#FFFFFF26]">
            <div className="relative flex justify-between mx-[4%]">
              <p className="font-sans text-[16px] font-bold leading-[1.26] text-left">
                Withdraw Details
              </p>
              <img
                src={Images.close}
                onClick={() => {
                  onClose();
                  setAmount("");
                  setError("");
                }}
                className=" right-6 size-4 cursor-pointer"
                alt="close-icon"
              />
            </div>
            <p className="font-sans text-[#9B9B9B] text-[12px] font-normal leading-6 text-left ml-[4%]">
              Any questions? Find more information in our{" "}
              <Link to="/faqs">
                <span className="text-primary">FAQ</span>
              </Link>
            </p>
            {withdrawNextStep ? (
              <>
                <div className="mx-[4%] mt-6 gap-4 flex justify-between">
                  <div className="w-full">
                    <p className="font-helvetica text-[12px] text-[#9B9B9B] font-normal leading-6 text-left">
                      Selected Coin
                    </p>
                    <div className="border mt-1 border-[#FFFFFF80] flex gap-2 px-2 h-12 rounded">
                      <img
                        src="/icon/usdtnew.png"
                        className="w-20 h-8 my-auto"
                      />
                      <p className="font-sora text-[14px] font-bold leading-[17.64px] my-auto text-center text-[#FFFFFFBF]">
                        USDT
                      </p>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="font-helvetica text-[12px] text-[#9B9B9B] font-normal leading-6 text-left">
                      Selected Chain
                    </p>
                    <div className="border mt-1 border-[#FFFFFF80]  px-2 h-12 rounded">
                      <p className="font-sora text-[14px] font-bold leading-[17.64px] mt-4 text-left text-[#FFFFFFBF]">
                        {network}
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="my-6 mx-[4%] border-t border-[#FFFFFF26]" />
                <div>
                  {!amountOptions ? (
                    <div className="bg-[#28263B] rounded-lg flex flex-col items-center justify-center mx-[4%] pb-6">
                      <p className="font-helvetica text-[12px] font-normal text-white mx-6 pt-9 leading-[18px] text-left">
                        Please confirm that you have verified the correct
                        network with your withdrawal address and understand that
                        an incorrect network/wallet selection may lead to
                        irreversible loss of assets.
                      </p>
                      <button
                        className="px-9 border border-primary rounded mt-11"
                        aria-label="Acknowledge button"
                        onClick={handleAmountOptions}
                      >
                        <span className="font-sora text-[10px] font-bold leading-[23.94px] text-primary text-center">
                          Acknowledge
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div className="mx-[4%]">
                      <div className="flex justify-between">
                        <p className="font-sans text-[12px] font-normal text-[#9B9B9B] leading-[24px] text-left">
                          Wallet Address
                        </p>
                        <div className="gap-1 flex">
                          <img
                            src="/icon/plus.png"
                            className=" size-2.5 mt-0.5"
                          />
                          <p className="font-sora text-[10px] font-normal text-primary leading-[12.6px] text-center">
                            Add
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center mt-1 border border-[#FFFFFF80] p-1 rounded">
                        <input
                          type="text"
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                            setAddressError("");
                          }}
                          placeholder="Please enter your wallet address..."
                          className="flex-1 p-3 text-white text-[12px] font-normal h-4 bg-transparent border-none rounded outline-none focus:ring-0"
                        />
                        <button
                          onClick={handlePaste}
                          className="ml-3 bg-[#00000026] flex gap-1 px-4 py-2 rounded my-1"
                        >
                          <img src="/icon/paste.png" className="size-4" />
                          <span className="font-sora text-[10px] font-normal leading-[12.6px] text-center text-[#FFFFFFBF]">
                            Paste Address
                          </span>
                        </button>
                      </div>
                      <p className="font-helvetica text-[12px] text-red-600 font-normal leading-[24px] text-left">
                        {addressError}
                      </p>
                      <div className="flex justify-between mt-4">
                        <p className="font-sans text-[12px] font-normal text-[#9B9B9B] leading-[24px] text-left">
                          Withdrawable Amount
                        </p>
                        <div className="gap-1 flex">
                          <img
                            src="/icon/wamount.png"
                            className=" size-2.5 mt-0.5"
                          />
                          <p
                            className="font-sora text-[10px] font-normal text-primary leading-[12.6px] text-center cursor-pointer"
                            onClick={() => setAmount(total)}
                          >
                            Max. Amount
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center mt-1 border border-[#FFFFFF80] p-1 rounded">
                        <input
                          type="tel"
                          value={amount}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === "" || value > 0) {
                              if (value > total) {
                                setAmountError(
                                  `your total balance is ${total}`
                                );
                              } else {
                                setAmount(value);
                                setAmountError("");
                              }
                            }
                          }}
                          placeholder="Please enter your  Withdrawal Amount... (min. 10 USDT)"
                          className="flex-1 p-3 text-white text-[12px] py-5 font-normal h-4 bg-transparent border-none rounded outline-none focus:ring-0"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <p className="font-helvetica text-[12px] mx-[4%] text-red-600 font-normal leading-[24px] text-left">
                  {amountError}
                </p>
                <div className="flex gap-2 justify-center items-center mt-6">
                  <div className={`w-1 h-1 rounded-full bg-[#6d6d6d]`}></div>
                  <div className={`w-1 h-1 rounded-full bg-[#fff1f1b8]`}></div>
                </div>
              </>
            ) : (
              <>
                <div className="flex gap-2 ml-[4%] mt-6">
                  <p className="font-helvetica text-[#9B9B9B] text-[12px] font-normal leading-[24px] text-left">
                    Select Coin
                  </p>
                  <img src="/icon/i.png" className="size-4 my-auto" />
                </div>
                <div
                  className="flex p-2 border mt-2 border-[#FFFFFF80] mx-[4%] rounded gap-3 cursor-pointer"
                  onClick={() => handleOptionClick("Crypto Payment")}
                >
                  <img
                    src="/icon/usdtnew.png"
                    className="w-20 h-8 rounded-lg"
                    alt="usdtnew Icon"
                  />
                  <div className="flex justify-between items-center flex-grow">
                    <div>
                      <p className="font-sora text-[14px] text-[#FFFFFFBF] font-bold leading-[15.12px] text-left">
                        {coin}
                      </p>
                    </div>
                    {selectedOption === "Crypto Payment" && (
                      <img
                        src="/icon/dad.svg"
                        className="w-5 h-5 rounded-lg"
                        alt="Selected Icon"
                      />
                    )}
                  </div>
                </div>
                <hr className="my-6 mx-[4%] border-t border-[#FFFFFF26]" />
                <div className="flex gap-4 ml-[4%] mt-6">
                  <p className="font-helvetica text-[12px] text-[#9B9B9B] font-normal leading-[24px] text-left">
                    Choose Chain
                  </p>
                  <img src="/icon/i.png" className="size-4 my-auto" />
                </div>
                <div className="flex justify-center mt-1">
                  {loadingChain ? (
                    <img
                      src="/icon/yellowloader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    <div className="w-[92%] ">
                      <div className="relative">
                        <div
                          className="appearance-none bg-[#211E34E5] border border-[#FFFFFF80] text-[#FFFFFFBF] font-semibold w-full py-2.5 px-4 rounded cursor-pointer"
                          onClick={toggleDropdown}
                        >
                          {network ? (
                            <>
                              {
                                networkOptions?.find(
                                  (option) => option.chain === network
                                )?.chain
                              }
                              <span className="text-[10px] font-normal ml-4">
                                {network === "ETH"
                                  ? "8.00 USDT Transaction fee"
                                  : "1.00 USDT Transaction fee"}
                              </span>
                            </>
                          ) : (
                            <span className="text-[#FFFFFF80]">
                              Select Network
                            </span>
                          )}
                        </div>
                        {isOpen && (
                          <ul className="absolute left-0 right-0 bg-[#211E34E5] border border-[#FFFFFF80] text-[#FFFFFFBF] font-semibold rounded ">
                            {networkOptions?.map((networkOption, index) => {
                              let displayValue = networkOption?.chain;
                              const networkInfo = {
                                ETH: "ETH (ERC20)",
                                BSC: "BSC (BEP20)",
                                ARBI: "ARBITRUM ONE",
                                MATIC: "POLYGON PoS",
                                OP: "OP MAINNET",
                              };

                              if (networkInfo[networkOption?.chain]) {
                                displayValue = (
                                  <div className="text-[12px] font-semibold">
                                    {networkInfo[networkOption?.chain]}{" "}
                                    <span className="text-[10px] font-normal ml-4">
                                      {displayValue === "ETH"
                                        ? "8.00 USDT Transaction fee"
                                        : "1.00 USDT Transaction fee"}
                                    </span>
                                  </div>
                                );
                              }
                              return (
                                <li
                                  key={index}
                                  className="px-4 py-2 cursor-pointer hover:bg-[#3a365f] rounded"
                                  onClick={() =>
                                    handleSelect(networkOption?.chain)
                                  }
                                >
                                  {displayValue}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        <div className="absolute right-3 bg-[#211E34E5] top-1/2 transform cursor-pointer -translate-y-1/2">
                          <img
                            src="/icon/dad.svg"
                            className="w-5 h-5 rounded-lg"
                            alt="Selected Icon"
                          />
                        </div>
                      </div>
                      {/* <div className="relative">
                        <select
                          className="appearance-none bg-[#211E34E5] border border-[#FFFFFF80] text-[#FFFFFFBF] font-semibold w-full h-12 px-4 rounded cursor-pointer"
                          value={network}
                          onChange={(e) => {
                            setNetwork(e.target.value);
                            setError("");
                          }}
                        >
                          <option value="" disabled>
                            Select Network
                          </option>
                          {networkOptions?.map((networkOption, index) => {
                            let displayValue = networkOption?.chain;
                            if (networkOption?.chain === "ETH") {
                              displayValue = "ERC20 8.00 USDT Transaction fee ";
                            } else if (networkOption?.chain === "BSC") {
                              displayValue =
                                "BSC (BEP20) 1.00 USDT Transaction fee ";
                            } else if (networkOption?.chain === "ARBI") {
                              displayValue =
                                "ARBITRUM ONE 1.00 USDT Transaction fee ";
                            } else if (networkOption?.chain === "MATIC") {
                              displayValue =
                                "POLYGON PoS 1.00 USDT Transaction fee ";
                            } else if (networkOption?.chain === "OP") {
                              displayValue =
                                "OP MAINNET 1.00 USDT Transaction fee ";
                            }
                            return (
                              <option key={index} value={networkOption?.chain}>
                                {displayValue}
                              </option>
                            );
                          })}
                        </select>
                      </div> */}
                    </div>
                  )}
                </div>
                <p className="font-helvetica text-[12px] text-red-600 mx-[4%] font-normal leading-[24px] text-left">
                  {error}
                </p>
                <div className="flex gap-2 justify-center items-center mt-6">
                  <div className={`w-1 h-1 rounded-full bg-[#fff1f1b8]`}></div>
                  <div className={`w-1 h-1 rounded-full bg-[#6d6d6d]`}></div>
                </div>
              </>
            )}
            <div className="mx-[4%] justify-between flex gap-6 my-6">
              <button
                className="w-[30%] border border-[#FFFFFF80] py-2 rounded"
                onClick={() => {
                  if (withdrawNextStep) {
                    setWithdrawNextStep(false);
                  } else {
                    onClose();
                    setAmount("");
                    setError("");
                  }
                }}
              >
                <span className="font-sora text-[12px] font-bold  text-[#FFFFFF80]">
                  {withdrawNextStep ? "Back" : "Cancel"}
                </span>
              </button>
              <button
                className="w-[30%] border border-primary bg-primary py-2 rounded"
                onClick={handleNextWStepWithdraw}
              >
                <span className=" font-sora text-[12px] font-bold text-black">
                  {withdrawNextStep ? "Confirm" : "Next Step"}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>

      {modalState.bankTransferModal && (
        <FiatWithdrawModal
          show={modalState.bankTransferModal}
          onClose={() => handleCloseModal("bankTransferModal")}
        />
      )}
      {/* {modalState.cryptoWithdrawModal && (
        <CryptoWithdrawModal
          amount={finalAmount}
          show={modalState.cryptoWithdrawModal}
          setTransferBalance={setTransferBalance}
          transferBalance={transferBalance}
          onClose={() => handleCloseModal("cryptoWithdrawModal")}
          closemainmodal={onClose}
        />
      )} */}
      {modalState.creditCardModal && (
        <AlgoPoolModal
          show={modalState.creditCardModal}
          onClose={() => handleCloseModal("creditCardModal")}
        />
      )}
      {modalState.verificationModal && (
        <DepositVerificationModal
          show={modalState.verificationModal}
          onClose={() => handleCloseModal("verificationModal")}
          type="withdraw"
        />
      )}
    </div>
  );
}

export default WithdrawModal;
