

import React, { createContext, useState, useEffect } from 'react';
import { fetchUserProfile } from '../backendServices/ApiCalls'; 

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const fetchUserFromAPI = async (token) => {
    try {
      const response = await fetchUserProfile(token);
      if (response.data.status === 'success') {
        setUser(response.data.user);
        localStorage.setItem("user", JSON.stringify(response.data.user));
      } else {
        localStorage.removeItem("user");
        setUser(null); 
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setUser(null);
      }
    }
  };
  // const verifyToken = () => {
  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     fetchUserFromAPI(token);
  //   } else {
  //     setUser(null); 
  //   }
  // };
  const verifyToken = () => {
    const token = localStorage.getItem("token");
    const localUser = localStorage.getItem("user");

    if (localUser) {
      setUser(JSON.parse(localUser)); 
      setLoading(false);
    }

    if (token) {
      fetchUserFromAPI(token); 
    } else {
      setUser(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, verifyToken,loading }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
