import React, { useContext, useState } from "react";
import { Dropdown, Space } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postRequest } from "../../backendServices/ApiCalls";
import Notification from "../../components/Notification";

function ContactUs() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };
  const items = [
    {
      key: "1",
      label: (
        <Link to="/usersetting" className="text-accent hover:text-primary">
          Account settings
        </Link>
      ),
    },
    user && {
      key: "2",
      label: (
        <Link className="text-accent hover:text-primary" onClick={logout}>
          Logout
        </Link>
      ),
    },
  ];
  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Name is required"),
    Email: Yup.string().required("Email is required"),
    Message: Yup.string().required("Message is required"),
    Regarding: Yup.string().required("Regarding is required"),
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      postRequest(
        "no",
        "auth/sendMessage",
        { postData: values },
        (response) => {
          Notification.showNotification(
            "success",
            "Success",
            "Message sent successfully!"
          );
          resetForm();
          setLoading(false);
        },
        (error) => {
          Notification.showNotification(
            "error",
            "Error",
            error.response?.data?.message || error.message
          );
          console.error(
            "Error:",
            error.response?.data?.message || error.message
          );
          setLoading(false);
        }
      );
    } catch (error) {
      console.error("Unexpected Error:", error.message);
      setLoading(false);
    }
  };

  return (
    <div className="body">
      <div className="navbar-bg-color min-h-[100vh] px-2 lg:px-4">
        <div className="grid grid-cols-5 items-center mx-[3%]">
          <div className=" hidden sm:block w-40 sm:w-72 md:w-72"></div>
          <div className="flex justify-between mt-6 col-span-5 sm:col-span-4 items-center ml-4 w-full">
            <div>
              <div className="flex flex-col sm:flex-row gap-9 ">
                <p className="font-sora my-auto text-md lg:text-[26px] font-extrabold leading-[62.72px] tracking-[-1.68px] text-left text-accent">
                  Welcome {user?.name}
                  <span className="font-sora text-[14px] font-bold leading-[23.94px] ml-2 text-center text-primary px-2 py-1 rounded-2xl bg-[#FFBF0026]">
                    Beta
                  </span>
                </p>
              </div>
            </div>
            <Space direction="vertical">
              <Space wrap>
                <Dropdown
                  overlayClassName="custom-dropdown-menu"
                  menu={{ items }}
                  placement="bottomLeft"
                  trigger={["click"]}
                >
                  <div className="flex gap-5 items-center bg-[#191919] px-4 py-2 rounded-3xl">
                    <img
                      src="/icon/userprofile.png"
                      className="cursor-pointer h-6 w-6"
                      alt="User Profile"
                    />
                    <img
                      src="/icon/userprofiledownarrow.png"
                      className="cursor-pointer h-2 w-3"
                      alt="Dropdown Arrow"
                    />
                  </div>
                </Dropdown>
              </Space>
            </Space>
          </div>
        </div>

        <div className="grid sm:grid-cols-5 ">
          <div className="sm:col-span-1 hidden sm:block border-r min-h-[85vh] border-[#404040]">
            <div
              onClick={() => navigate("/dashboard")}
              className={`flex gap-2 px-3 mt-4 cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl ${
                location.pathname === "/dashboard"
                  ? "border-white border text-white"
                  : "border-[#404040] text-[#9B9B9B]"
              }`}
            >
              <img
                src={
                  location.pathname === "/dashboard"
                    ? "/icon/dashboard2.svg"
                    : "/icon/dashboard.svg"
                }
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="dashboardicon"
              />
              <p className="font-sans text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                Dashboard
              </p>
            </div>
            <div
              onClick={() => navigate("/user-transaction")}
              className={`flex gap-2 px-3 mt-4 cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl ${
                location.pathname === "/user-transaction"
                  ? "border-white border text-white"
                  : "border-[#404040] text-[#9B9B9B]"
              }`}
            >
              <img
                src={
                  location.pathname === "/user-transaction"
                    ? "/icon/Transaction2.svg"
                    : "/icon/Transaction.svg"
                }
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="transactionicon"
              />
              <p className="font-sans text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                Transaction History
              </p>
            </div>

            <hr className="mx-2 lg:mx-8 my-4 border-t border-[#404040]" />
            <div
              className={`flex gap-2 px-3 mt-4 cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl ${
                location.pathname === "/contactus"
                  ? "border-white border text-white"
                  : "border-[#404040] text-[#9B9B9B]"
              }`}
            >
              <img
                src={
                  location.pathname === "/contactus"
                    ? "/icon/contact2.png"
                    : "/icon/contact.png"
                }
                className="size-4 my-auto lg:size-5 cursor-pointer"
                alt="contacticon"
              />
              <p className="font-sans text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                Contact Us
              </p>
            </div>
          </div>
          <div className="col-span-4 my-4 px-[4%] md:w-[65%] lg:w-[45%]">
            <p className="font-sora text-2xl font-extrabold leading-[26.88px]">
              Get In touch
            </p>
            <p className="font-sora text-sm font-normal leading-[19.6px] mt-4 text-[#AAAAAA]">
              Have a question or want to leave some Feedback? We'd love to hear
              from you!
            </p>
            <Formik
              initialValues={{
                Name: "",
                Email: "",
                Message: "",
                Regarding: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {() => (
                <Form className="mt-6">
                  <div>
                    <p className="font-sora text-base font-semibold leading-[19.2px]">
                      Name
                    </p>
                    <Field
                      name="Name"
                      placeholder="Your Name"
                      type="text"
                      className="w-full h-11 bg-transparent border px-4 font-sora rounded-xl mt-2 border-[#404040]"
                    />
                    <ErrorMessage
                      name="Name"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div className="mt-4">
                    <p className="font-sora text-base font-semibold leading-[19.2px]">
                      Email
                    </p>
                    <Field
                      name="Email"
                      placeholder="Your Email"
                      type="email"
                      className="w-full h-11 bg-transparent border px-4 font-sora rounded-xl mt-2 border-[#404040]"
                    />
                    <ErrorMessage
                      name="Email"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div className="mt-4">
                    <p className="font-sora text-base font-semibold leading-[19.2px]">
                      Regarding
                    </p>
                    <Field
                      name="Regarding"
                      placeholder="Regarding"
                      type="text"
                      className="w-full h-11 bg-transparent border px-4 font-sora rounded-xl mt-2 border-[#404040]"
                    />
                    <ErrorMessage
                      name="Regarding"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div className="mt-4">
                    <p className="font-sora text-base font-semibold leading-[19.2px]">
                      Message
                    </p>
                    <Field
                      as="textarea"
                      name="Message"
                      placeholder="Your Message"
                      className="w-full h-[144px] bg-transparent border py-2 px-3 font-sora rounded-xl mt-2 border-[#404040] resize-none"
                    />
                    <ErrorMessage
                      name="Message"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="mt-4 gap-2 bg-transparent min-w-20 sm:min-w-40 text-white flex px-5 border border-primary py-2 rounded"
                  >
                    {loading ? (
                      <img
                        src="/icon/loaderwhite.svg"
                        alt="Loading..."
                        className="w-7 mx-auto h-7 animate-spin"
                      />
                    ) : (
                      <>
                        <img
                          src="/icon/sendmes.png"
                          className="size-5 my-auto"
                          alt="Send"
                        />
                        <p className="font-sora text-xs font-bold leading-[23.94px] my-auto text-center text-primary">
                          Send Message
                        </p>
                      </>
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
