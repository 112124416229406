import React from "react";
import Featurecard from "./Featurecard";

function Feature() {
  return (
    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
      <p className="font-sora text-[16px] text-primary mt-44 font-normal leading-[22.4px] tracking-[4.8px] text-center">
        FEATURES
      </p>
      <p className="font-sora mx-2  mt-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center text-[34px] sm:text-[50px]">
      OUR CORE STRENGTHS
      </p>
      <div className=" flex lg:flex-row flex-col mt-12  justify-center gap-8 items-center  ">
        <Featurecard
          img="/icon/1.svg"
          h1="No experience required"
          p="Professional algorithmic trading available for both novice and experienced investors"
        />
        <Featurecard
          img="/icon/2.svg"
          h1="Security first"
          p="Your funds are safe with the world's second-largest cryptocurrency exchange Bybit"
        />
        <Featurecard
          img="/icon/3.svg"
          h1="Set & forget"
          p="Set up our smart trading bot in just
10 minutes and let it work for you 24/7"
        />
      </div>
      <div className=" flex lg:flex-row flex-col mt-8 sm:mt-0   justify-center gap-8 items-center  ">
        <Featurecard
          img="/icon/4.svg"
          h1="Long-term"
          p="Designed for long-term success with proven risk management strategies"
        />
        <Featurecard
          img="/icon/5.svg"
          h1="Continuous Development"
          p="Regular refinement of trading algorithms to maintain optimal performance"
        />
        <Featurecard
          img="/icon/6.svg"
          h1="Full Transparency"
          p="Real-time monitoring of your investment through our comprehensive dashboard"
        />
      </div>
    </div>
  );
}

export default Feature;
